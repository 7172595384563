import React, { useState } from "react";
import { PhotoSlider } from 'react-photo-view';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import './index.scss';

const banner = [
  require('../../assets/banner/banner-01.jpg').default,
  require('../../assets/banner/banner-02.jpg').default,
  require('../../assets/banner/banner-03.jpg').default,
  require('../../assets/banner/banner-04.jpg').default,
  require('../../assets/banner/banner-05.jpg').default,
]
const imgs = [
  require('../../assets/partner-home.png').default
]

SwiperCore.use([Autoplay]);

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)
  return (
    <div className="zr-page p-page">
      <div className="banner">
        <Swiper
          slidesPerView={1}
          initialSlide={0}
          loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false
          }}
        >
          {
            banner.map((item, index) => {
              return <SwiperSlide key={index}>
                <img src={item} alt="图片" />
              </SwiperSlide>
            })
          }
        </Swiper>
        <div className="slogan">
          <p><span className="t1">加入中壬</span><span>以诚为本</span></p>
          <p><span className="t1">资源共享</span><span>平台互利</span></p>
        </div>
      </div>
      <div className="zr-page-sec">
        <div className="sec-desc">公司团队深耕建筑设计及相关行业二十余年，拥有建筑工程甲级资质，以及城市规划设计与市政行业等相关资质。</div>
        <div className="sec-desc" style={{ marginTop: '.4rem' }}>现<span style={{ color: '#c23e3f' }}>诚邀</span>行业内优秀团队及个人，以设立分公司、项目合作等形式协同发展。公司将以服务为本的理念，与合作团队共享资源与经验，且保证各团队利益最大化，工作高效化。</div>
        <div className="sec1">
          <img src={require('../../assets/partner-home.png').default} onClick={() => setShowModal(true)} />
          <div className="info">
            <p className="tel">合作热线：<a href="tel:4009982282">400-998-2282</a></p>
            <p className="sec-desc">公司注重将核心团队技术能力与管理执行相结合，秉承诚信为本互利共赢的原则，致力于更好的服务于客户及各地区合作伙伴。</p>
          </div>
        </div>
      </div>
      <div className="zr-page-sec company">
        <div className="c-box c-sx">
          <p className="t1">中壬建筑<span className="dot"></span>山西</p>
          <p className="t2">山西省朔州市开发区文远路87号</p>
        </div>
        <div className="c-box c-bj">
          <p className="t1">中壬建筑<span className="dot"></span>北京</p>
          <p className="t2">北京市海淀区彩和坊路8号天创科技大厦</p>
        </div>
        <div className="c-box c-qh">
          <p className="t1">中壬建筑<span className="dot"></span>青海</p>
          <p className="t2">青海省西宁市城西区万达中心4号写字楼</p>
        </div>
        <div className="c-box c-fz">
          <p className="t1">中壬建筑<span className="dot"></span>福州</p>
          <p className="t2">福建省福州市仓山区维温产业园1号楼</p>
        </div>
        <div className="c-box c-dg">
          <p className="t1">中壬建筑<span className="dot"></span>东莞</p>
          <p className="t2">广东省东莞市南城区高盛科技大厦</p>
        </div>
        <div className="c-box c-ll">
          <p className="t1">中壬建筑<span className="dot"></span>吕梁</p>
          <p className="t2">山西省吕梁市离石区状元城一号</p>
        </div>
      </div>
      <img className="pic-map" src={require('../../assets/partner-map.png').default} />
      {/* 图片预览 */}
      <PhotoSlider
        images={imgs.map((item) => ({ src: item }))}
        visible={showModal}
        onClose={() => setShowModal(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}