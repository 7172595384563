import React, { useState } from "react";
import { PhotoSlider } from 'react-photo-view';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import './index.scss';

const imgs = [
  require('../../assets/sy-dp1.png').default
]

const banner = [
  require('../../assets/banner/banner-01.jpg').default,
  require('../../assets/banner/banner-02.jpg').default,
  require('../../assets/banner/banner-03.jpg').default,
  require('../../assets/banner/banner-04.jpg').default,
  require('../../assets/banner/banner-05.jpg').default,
]
SwiperCore.use([Autoplay]);
export default () => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  return (
    <div className="zr-page index-page">
      <div className="banner">
        <Swiper
          slidesPerView={1}
          initialSlide={0}
          loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false
          }}
        >
          {
            banner.map((item, index) => {
              return <SwiperSlide key={index}>
                <img src={item} alt="图片" />
              </SwiperSlide>
            })
          }
        </Swiper>
      </div>
      <div className="zr-page-sec">
        <p className="sec-title">关于我们</p>
        <p className="sec-desc">中壬建筑设计有限公司，在建筑设计以及工程施工和勘察领域都有丰富的经验累积，公司团队核心人员拥有二十余年的技术沉淀以及管理经验。</p>
      </div>
      <div className="zr-page-sec">
        <p className="sec-title">业务领域</p>
        <div className="index-page-yw">
          <Swiper
            slidesPerView={3}
            initialSlide={0}
            loop
          >
            <SwiperSlide>
              <div className="box">
                <img src={require('../../assets/sy-fasj.png').default} />
                <p>方案设计</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <img src={require('../../assets/sy-gcsj.png').default} />
                <p>工程设计</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <img src={require('../../assets/sy-szsj.png').default} />
                <p>市政设计</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <img src={require('../../assets/sy-csgh.png').default} />
                <p>城乡规划</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <img src={require('../../assets/sy-epc.png').default} />
                <p>EPC总包</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="box">
                <img src={require('../../assets/sy-zhjz.png').default} />
                <p>智慧建筑</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="zr-page-sec">
        <p className="sec-title">数字城市与智慧楼宇</p>
        <img className="dp1" src={require('../../assets/sy-dp1.png').default} onClick={() => setShowModal(true)} />
      </div>
      {/* 图片预览 */}
      <PhotoSlider
        images={imgs.map((item) => ({ src: item }))}
        visible={showModal}
        onClose={() => setShowModal(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}