import React, { useState } from "react";
import { PhotoSlider } from 'react-photo-view';
import './index.scss';

const imgs = [require('../../assets/footer-code.png').default]

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  return (
    <div className="zr-footer">
      <div className="zr-footer-left">
        <img className="logo" src={require('../../assets/footer-logo.png').default} />
        <img className="txt" src={require('../../assets/footer-txt.png').default} />
        {/* <img className="code" src={require('../../assets/footer-code.png').default} /> */}
      </div>
      <div className="zr-footer-right">
        <p style={{marginBottom: '.2rem'}}>电话</p>
        <a href="tel:4009982282" style={{color: '#fff'}}>400-998-2282</a>
        <p style={{marginTop: '.2rem', marginBottom: '.2rem'}}>地址</p>
        <p>北京市海淀区彩和坊路8号天创科技大厦</p>
        <p>朔州市开发区文远路87号</p>
        <p style={{marginTop: '.2rem', marginBottom: '.2rem'}}>邮箱</p>
        <p className="email">
          <span>zrarc_ty@163.com</span>
          <img src={require('../../assets/wechat.png').default} onClick={() => setShowModal(true)} />
        </p>
      </div>
      {/* 图片预览 */}
      <PhotoSlider
        images={imgs.map((item) => ({ src: item }))}
        visible={showModal}
        onClose={() => setShowModal(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}