import React, { useEffect } from "react";
import classNames from "classnames";
import { Link } from 'react-router-dom';
import './index.scss';

export default (props) => {
  const { showMenu, menuHandle } = props

  return (
    <div className="zr-header">
      <Link to="/index" className="zr-header-logo">
        <span>中壬建筑</span>
        <span className="dot"></span>
        <span>ZRARC</span>
      </Link>
      <div className={classNames('zr-header-menu', showMenu && 'close')} onClick={menuHandle}>
        <div>
          <div className="rect top"></div>
          <div className="rect middle"></div>
          <div className="rect bottom"></div>
        </div>
      </div>
    </div>
  )
}