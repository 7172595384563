import React from "react";
import './index.scss';

export default () => {
  return (
    <div className="zr-page c-page">
      <div className="map-box">
        <img className="map" src={require('../../assets/map-bj.png').default} />
        <div className="map-tag">
          <p>中壬建筑设计有限公司(北京)</p>
          <span className="map-circle"></span>
        </div>
      </div>
      <div className="zr-page-sec">
        <p className="name">中壬建筑设计有限公司(北京)</p>
        <p className="txt">联系地址：北京市海淀区彩和坊路8号天创科技大厦</p>
        <p className="txt">联系电话：400-998-2282</p>
      </div>
      <div className="map-box">
        <img className="map" src={require('../../assets/map-sx.png').default} />
        <div className="map-tag map-tag1">
          <p>中壬建筑设计有限公司(山西)</p>
          <span className="map-circle"></span>
        </div>
      </div>
      <div className="zr-page-sec">
        <p className="name">中壬建筑设计有限公司(山西)</p>
        <p className="txt">联系地址：朔州市开发区文远路87号</p>
        <p className="txt">联系电话：400-998-2282</p>
      </div>
    </div>
  )
}