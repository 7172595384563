import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Index from '../../pages/index';
import About from '../../pages/about';
import Wisdom from '../../pages/wisdom';
import Honor from '../../pages/honor';
import Partner from '../../pages/partner';
import Contact from '../../pages/contact';
import Header from '../Header';
import Footer from '../Footer';
import './index.scss';

export default () => {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const [pathname, setPathname] = useState(location.pathname)

  useEffect(() => {
    window.addEventListener("hashchange", function (e) {
      if (e.newURL !== e.oldURL) {
        setPathname(e.newURL.split('/#')[1])
        setShowMenu(false)
      }
    }, false);
  }, [])

  const menuHandle = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div className={classNames('zr-wrapper', showMenu && 'open')}>
      <div className="zr-container">
        <Header showMenu={showMenu} menuHandle={menuHandle} />
        <div className="zr-router">
          <Switch>
            <Route path="/index" component={Index} />
            <Route path="/about" component={About} />
            <Route path="/wisdom" component={Wisdom} />
            <Route path="/honor" component={Honor} />
            <Route path="/partner" component={Partner} />
            <Route path="/contact" component={Contact} />
            <Redirect exact from="/" to="/index" />
          </Switch>
          <Footer />
        </div>
        
      </div>
      {/* 侧边栏 */}
      <div className="zr-side">
        <ul className="zr-side-nav">
          <li className={pathname === '/index' ? 'active' : undefined}><Link to="/index">首页</Link></li>
          <li className={pathname === '/about' ? 'active' : undefined}><Link to="/about">关于我们</Link></li>
          <li className={pathname === '/wisdom' ? 'active' : undefined}><Link to="/wisdom">智慧建筑</Link></li>
          <li className={pathname === '/honor' ? 'active' : undefined}><Link to="/honor">荣誉资质</Link></li>
          <li className={pathname === '/partner' ? 'active' : undefined}><Link to="/partner">城市合伙人</Link></li>
          <li className={pathname === '/contact' ? 'active' : undefined}><Link to="/contact">联系我们</Link></li>
        </ul>
      </div>
    </div>
  )
}