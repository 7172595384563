import React, { useState } from "react";
import { PhotoSlider } from 'react-photo-view';
import './index.scss';

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)
  const [imgs, setImgs] = useState([])

  const showModalHandle = (project) => {
    switch (project) {
      case 1:
        setImgs([
          require('../../assets/project/01-1.jpg').default,
          require('../../assets/project/01-2.jpg').default,
        ])
        break;
      case 2:
        setImgs([
          require('../../assets/project/02-1.jpg').default,
          require('../../assets/project/02-2.jpg').default,
        ])
        break;
      case 3:
        setImgs([
          require('../../assets/project/03-1.jpg').default,
        ])
        break;
      case 4:
        setImgs([
          require('../../assets/project/04-1.jpg').default,
          require('../../assets/project/04-2.jpg').default,
          require('../../assets/project/04-3.jpg').default,
        ])
        break;
      case 5:
        setImgs([
          require('../../assets/project/05-1.jpg').default,
          require('../../assets/project/05-2.jpg').default,
          require('../../assets/project/05-3.jpg').default,
          require('../../assets/project/05-4.jpg').default,
        ])
        break;
      case 6:
        setImgs([
          require('../../assets/project/06-1.jpg').default,
          require('../../assets/project/06-2.jpg').default,
        ])
        break;
      case 7:
        setImgs([
          require('../../assets/project/07-1.jpg').default,
          require('../../assets/project/07-2.jpg').default,
          require('../../assets/project/07-3.jpg').default,
        ])
        break;
      case 8:
        setImgs([
          require('../../assets/project/08-1.jpg').default,
          require('../../assets/project/08-2.jpg').default,
          require('../../assets/project/08-3.jpg').default,
        ])
        break;
      case 9:
        setImgs([
          require('../../assets/project/09-1.jpg').default,
        ])
        break;
      case 10:
        setImgs([
          require('../../assets/project/10-1.jpg').default,
          require('../../assets/project/10-2.jpg').default,
          require('../../assets/project/10-3.jpg').default,
        ])
        break;
      case 11:
        setImgs([
          require('../../assets/project/11-1.jpg').default,
          require('../../assets/project/11-2.jpg').default,
        ])
        break;
      case 12:
        setImgs([
          require('../../assets/project/12-1.jpg').default,
          require('../../assets/project/12-2.jpg').default,
          require('../../assets/project/12-3.jpg').default,
        ])
        break;
      case 13:
        setImgs([
          require('../../assets/project/13-1.jpg').default,
          require('../../assets/project/13-2.jpg').default,
          require('../../assets/project/13-3.jpg').default,
        ])
        break;
      case 14:
        setImgs([
          require('../../assets/project/14-1.jpg').default,
        ])
        break;
      case 15:
        setImgs([
          require('../../assets/project/15-1.jpg').default,
          require('../../assets/project/15-2.jpg').default,
        ])
        break;
      case 16:
        setImgs([
          require('../../assets/project/16-1.jpg').default,
        ])
        break;
      case 17:
        setImgs([
          require('../../assets/project/17-1.jpg').default,
        ])
        break;
      case 18:
        setImgs([
          require('../../assets/project/18-1.jpg').default,
        ])
        break;
    }
    setIndex(0)
    setShowModal(true)
  }

  return (
    <div className="zr-page about-page">
      <div className="zr-page-sec">
        <p className="sec-desc">中壬建筑设计有限公司，在建筑工程设计、工程施工和勘察领域都有丰富的行业积累和技术沉淀。公司始终秉承以人为本、质量第一、不断创新、优质服务的企业理念，凭借严谨的工作原则和优良的服务意识，在教育、办公、商业、医疗、城市规划、城市设计、园林设计、基桩工程设计地质测绘岩土勘察工程、概预算等方面都有丰富的经验。公司注重将核心团队技术能力与管理执行相结合，且秉承诚信为本、互利共赢的原则将来也会致力于更好的服务于客户及各地区合作伙伴。</p>
      </div>
      <div className="zr-page-sec">
        <p className="sec-title">项目展示</p>
        <ul className="pic-list">
          <li onClick={() => showModalHandle(1)}>
            <img className="sub-pic" src={require('../../assets/project/01-1.jpg').default} />
            <p className="sub-tit">山西农谷农副食品加工园区</p>
          </li>
          <li onClick={() => showModalHandle(2)}>
            <img className="sub-pic" src={require('../../assets/project/02-1.jpg').default} />
            <p className="sub-tit">平鲁区地理信息产业创新孵化基地</p>
          </li>
          <li onClick={() => showModalHandle(3)}>
            <img className="sub-pic" src={require('../../assets/project/03-1.jpg').default} />
            <p className="sub-tit">朔城区人民法院</p>
          </li>
          <li onClick={() => showModalHandle(4)}>
            <img className="sub-pic" src={require('../../assets/project/04-1.jpg').default} />
            <p className="sub-tit">大同市大十字街区改造</p>
          </li>
          <li onClick={() => showModalHandle(5)}>
            <img className="sub-pic" src={require('../../assets/project/05-1.jpg').default} />
            <p className="sub-tit">介休市汾秀学校建设项目</p>
          </li>
          <li onClick={() => showModalHandle(6)}>
            <img className="sub-pic" src={require('../../assets/project/06-1.jpg').default} />
            <p className="sub-tit">介休市体育馆</p>
          </li>
          <li onClick={() => showModalHandle(7)}>
            <img className="sub-pic" src={require('../../assets/project/07-1.jpg').default} />
            <p className="sub-tit">北京新学道介休书院</p>
          </li>
          <li onClick={() => showModalHandle(8)}>
            <img className="sub-pic" src={require('../../assets/project/08-1.jpg').default} />
            <p className="sub-tit">朔州市朔城区职业中学</p>
          </li>
          <li onClick={() => showModalHandle(9)}>
            <img className="sub-pic" src={require('../../assets/project/09-1.jpg').default} />
            <p className="sub-tit">平鲁区高级中学</p>
          </li>
          <li onClick={() => showModalHandle(10)}>
            <img className="sub-pic" src={require('../../assets/project/10-1.jpg').default} />
            <p className="sub-tit">新时代开发路综合楼</p>
          </li>
          <li onClick={() => showModalHandle(11)}>
            <img className="sub-pic" src={require('../../assets/project/11-1.jpg').default} />
            <p className="sub-tit">平鲁区信息产业园</p>
          </li>
          <li onClick={() => showModalHandle(12)}>
            <img className="sub-pic" src={require('../../assets/project/12-1.jpg').default} />
            <p className="sub-tit">英国伦敦佩克汉姆区鱼菜共生产业园区</p>
          </li>
          <li onClick={() => showModalHandle(13)}>
            <img className="sub-pic" src={require('../../assets/project/13-1.jpg').default} />
            <p className="sub-tit">斗拱结构可行性拓展研究</p>
          </li>
          <li onClick={() => showModalHandle(14)}>
            <img className="sub-pic" src={require('../../assets/project/14-1.jpg').default} />
            <p className="sub-tit">太原基督教五一路教堂商务中心</p>
          </li>
          <li onClick={() => showModalHandle(15)}>
            <img className="sub-pic" src={require('../../assets/project/15-1.jpg').default} />
            <p className="sub-tit">朔州市朔城区大营街25号院基督教堂</p>
          </li>
          <li onClick={() => showModalHandle(16)}>
            <img className="sub-pic" src={require('../../assets/project/16-1.jpg').default} />
            <p className="sub-tit">宋金墓葬博物馆</p>
          </li>
          <li className="l1" onClick={() => showModalHandle(17)}>
            <img className="sub-pic" src={require('../../assets/project/17-1.jpg').default} />
            <p className="sub-tit">朔州市七里河公园景观规划设计</p>
          </li>
          <li className="l1" onClick={() => showModalHandle(18)}>
            <img className="sub-pic" src={require('../../assets/project/18-1.jpg').default} />
            <p className="sub-tit">2006-2020部分项目成果</p>
          </li>
        </ul>
      </div>
      {/* 图片预览 */}
      <PhotoSlider
        images={imgs.map((item) => ({ src: item }))}
        visible={showModal}
        onClose={() => setShowModal(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}