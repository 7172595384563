import React, { useState } from "react";
import { PhotoSlider } from 'react-photo-view';
import './index.scss';

const imgs = [
  'http://cdn.1ylswhcm.com/c1.jpeg',
  'http://cdn.1ylswhcm.com/c2.jpeg',
  'http://cdn.1ylswhcm.com/c3.jpeg',
  'http://cdn.1ylswhcm.com/c4.jpeg',
]

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  const setModalHandle = (i) => {
    setIndex(i)
    setShowModal(true)
  }

  return (
    <div className="zr-page h-page">
      <div className="zr-page-sec">
        <p className="sec-title">荣誉资质</p>
        <img className="pic" src="http://cdn.1ylswhcm.com/c1.jpeg" onClick={() => setModalHandle(0)} />
        <img className="pic" src="http://cdn.1ylswhcm.com/c2.jpeg" onClick={() => setModalHandle(1)} />
        <img className="pic" src="http://cdn.1ylswhcm.com/c3.jpeg" onClick={() => setModalHandle(2)} />
        <img className="pic" src="http://cdn.1ylswhcm.com/c4.jpeg" onClick={() => setModalHandle(3)} />
      </div>
      {/* 图片预览 */}
      <PhotoSlider
        images={imgs.map((item) => ({ src: item }))}
        visible={showModal}
        onClose={() => setShowModal(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}