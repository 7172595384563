import React, { useState } from "react";
import { PhotoSlider } from 'react-photo-view';
import './index.scss';

const imgs = [
  require('../../assets/sy-dp1.png').default,
  require('../../assets/sy-dp2.png').default,
  require('../../assets/sy-dp3.png').default,
  require('../../assets/sy-dp4.png').default,
  require('../../assets/sy-dp5.png').default,
]

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [index, setIndex] = useState(0)

  const setModalHandle = (i) => {
    setIndex(i)
    setShowModal(true)
  }

  return (
    <div className="zr-page w-page">
      <div className="zr-page-sec">
        <p className="sec-title">数字城市与智慧楼宇</p>
        <p className="sec-desc">城市数字化管理需打造一个统一平台，设立城市数据中心，通过分层建设，达到平台能力及应用的可成长、可补充，创造面向未来的智慧城市系统框架。中壬建筑设计有限公司针对智能化项目设计，以新建或改造建筑为平台，基于对各类智能化信息的综合应用，集架构、系统、应用、管理及优化组合为一体，形成以人、建筑、环境互为协调的整合体，为客户营造安全、高效、便利及具备可持续发展功能的建筑空间。</p>
        <img className="pic1" src={require('../../assets/wisdom-pic1.png').default} />
        <ul className="tags-list">
          <li>
            <span>智慧建筑物业管理</span>
            <span>信息引导发布系统</span>
          </li>
          <li>
            <span>能源使用（主动/被动）</span>
            <span>建筑设备监控系统</span>
          </li>
          <li>
            <span>建筑全过程服务</span>
            <span>智能照明系统</span>
          </li>
          <li>
            <span>客户服务，空间管理</span>
            <span>停车管理系统</span>
          </li>
          <li>
            <span>垃圾智能管理</span>
            <span>视频监控系统</span>
          </li>
          <li>
            <span>环境实时监测报告</span>
            <span>访客管理系统</span>
          </li>
          <li>
            <span>数据中心</span>
            <span>电梯运营监测系统</span>
          </li>
        </ul>
      </div>
      <div className="zr-page-sec">
        <p className="sec-title">智慧大屏案例展示</p>
        <img className="pic2" src={require('../../assets/sy-dp1.png').default} onClick={() => setModalHandle(0)} />
        <img className="pic2" src={require('../../assets/sy-dp2.png').default} onClick={() => setModalHandle(1)} />
        <img className="pic2" src={require('../../assets/sy-dp3.png').default} onClick={() => setModalHandle(2)} />
        <img className="pic2" src={require('../../assets/sy-dp4.png').default} onClick={() => setModalHandle(3)} />
        <img className="pic2" src={require('../../assets/sy-dp5.png').default} onClick={() => setModalHandle(4)} />
      </div>
      {/* 图片预览 */}
      <PhotoSlider
        images={imgs.map((item) => ({ src: item }))}
        visible={showModal}
        onClose={() => setShowModal(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  )
}